import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { metadataActions } from '../../store/metadata/metadata-actions';
import { Link } from 'react-router-dom';
// import logo from '../../assets/images/miza_logo.png';
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Switch, TextField, Typography, Button,
  Divider, Stack, useMediaQuery, InputAdornment,
  IconButton } from '@mui/material';
// import Lock from '@mui/icons-material/Lock';
import { GoogleLogin } from 'react-google-login';
import MizaConstants from '../../support/miza-constants';
// import MuiLink from '@mui/material/Link';
// import MizaEnums from '../../support/miza-enums';
// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type LoginProps = {
  login: (email: string, password: string, successCallback?: Function, errorCallback?: Function) => void;
  googleLogin: (tokenId: string, successCallback?: Function, errorCallback?: Function) => void;
};

const LoginPage = (props: LoginProps) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const { login, googleLogin } = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  const [wrongCredentialsMessage, setWrongCredentialsMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
    setEmailErrorMessage('');
  };

  const onPasswordChange = (event: any) => {
    setPassword(event.target.value);
    setPasswordErrorMessage('');
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleLogin = async () => {
    if (wrongCredentialsMessage) {
      // ToastHelper.removeToastMessage(wrongCredentialsToastId);
      setWrongCredentialsMessage('');
    }

    if (!email) {
      setEmailErrorMessage('The email address is required');
      return;
    }

    if (!password) {
      setPasswordErrorMessage('The password is required');
      return;
    }

    const errorCallback = () => {
      setWrongCredentialsMessage('Invalid email or password');
    };

    login(email, password, undefined, errorCallback);
  };
  const onLoginClick = () => {
    handleLogin();
  };

  // const onKeypress = (event: KeyboardEvent) => {
  //   if (event.key === 'Enter') {
  //     handleLogin();
  //   }
  // };

  const onGoogleSuccess = (response: any) => {
    googleLogin(response['tokenId'], undefined, () => setWrongCredentialsMessage('Invalid Login'));
  };
  const onGoogleFailure = (response: any) => {
    console.log(response);
  };
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  // const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  // const [passwordErrorMessage, setPasswordErrorMessage] = useState<string>('');
  // const [passwordRightTemplate, setPasswordRightTemplate] = useState<string>('Show');
  // const [passwordTextFieldType, setPasswordTextFieldType] = useState<string>('password');

  // const changePasswordTextFieldType = () => {
  //   setPasswordRightTemplate(passwordRightTemplate === 'Show' ? 'Hide' : 'Show');
  //   setPasswordTextFieldType(passwordRightTemplate === 'Show' ? 'text' : 'password');
  // };

  // const onEmailChange = (value: string) => {
  //   setEmail(value);
  //   setEmailErrorMessage('');
  // };

  // const onPasswordChange = (value: string) => {
  //   setPassword(value);
  //   setPasswordErrorMessage('');
  // };

  // const handleLogin = async () => {
  //   if (wrongCredentialsToastId) {
  //     ToastHelper.removeToastMessage(wrongCredentialsToastId);
  //   }

  //   if (!email) {
  //     setEmailErrorMessage('The email address is required');
  //     return;
  //   }

  //   if (!password) {
  //     setPasswordErrorMessage('The password is required');
  //     return;
  //   }

  //   const errorCallback = () => {
  //     setWrongCredentialsToastId(ToastHelper.addToastMessage({ message: 'The email or password provided is incorrect' }));
  //   };

  //   login(email, password, undefined, errorCallback);
  // };

  // const onLoginClick = () => {
  //   handleLogin();
  // };

  // const onKeypress = (event: KeyboardEvent) => {
  //   if (event.key === 'Enter') {
  //     handleLogin();
  //   }
  // };

  return (
    <div className='m-paper'>
      <Grid
        container
        spacing={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box boxShadow={2} borderRadius={3} px={3} className ='m-login-box'>
          <Box
            mt={4}
            mb={2}
            component="img"
            sx={{
              width: '60%',
            }}
            // sx={{
            //   height: 96,
            //   width: 96,
            //   alignSelf: 'center',
            //   textAlign: 'center',
            // }}
            alt="Miza logo"
            src="logo-miza.png"
          />
          {/* <Box mt={4}>
            <Lock color='primary' fontSize="large" />
          </Box> */}
          <Grid item>
            <Stack alignItems="center" justifyContent="center" spacing={1}>
              <Typography
                color={theme.palette.secondary.main}
                gutterBottom
                variant={matchDownSM ? 'h6' : 'h5'}>
                Welcome Back
              </Typography>
              <Typography
                variant="caption"
                fontSize="14px"
                textAlign={matchDownSM ? 'center' : 'inherit'}>
                Enter your credentials to continue
              </Typography>
            </Stack>
          </Grid>
          <Box pt={4} pb={2} style={{width: '100%'}}>
            <GoogleLogin
              clientId={MizaConstants.GOOGLE_CLIENT_ID}
              buttonText="Continue with Google"
              className='m-google-login'
              onSuccess={onGoogleSuccess}
              onFailure={onGoogleFailure}
              cookiePolicy={'single_host_origin'}
            />
          </Box>
          <Box style={{width: '100%'}}>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
              <Button
                variant="outlined"
                sx={{
                  cursor: 'unset',
                  minWidth: '30px',
                  m: 2,
                  py: 0.5,
                  px: 1,
                  borderColor: `${theme.palette.grey[300]} !important`,
                  color: `${theme.palette.grey[900]}!important`,
                  fontWeight: 500,
                  borderRadius: MizaConstants.BORDER_RADIUS_LARGE,
                }}
                disableRipple
                disabled
              >
                OR
              </Button>
              <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
            </Box>
          </Box>
          <Box pt={4} pb={2} style={{width: '100%'}}>
            <Box component="form" role="form">
              <Box mb={2}>
                <TextField size='small' type="email" label="Email" fullWidth onChange={(event: object) => onEmailChange(event)}
                  helperText={emailErrorMessage}
                  // onKeyPress={onKeypress}
                />
              </Box>
              <Box mb={2}>
                {/* <OutlinedInput
                  type={showPassword ? 'text' : 'password'}
                  value={values.password}
                  name="password"
                  onBlur={handleBlur}
                  onChange={onPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                  inputProps={{}}
                /> */}
                <TextField size='small'
                  type={showPassword ? 'text' : 'password'}
                  label="Password"
                  fullWidth onChange={(event: object) => onPasswordChange(event)}
                  helperText={passwordErrorMessage}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    // inputProps: {
                    //   list: 'rfc',
                    // },
                  }}
                  // onKeyPress={onKeypress}
                />
              </Box>
              <Box display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <Typography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                &nbsp;&nbsp;Remember me
                </Typography>
              </Box>
              <Box mt={4} mb={1}>
                <Button variant="contained" color="primary" fullWidth onClick={() => onLoginClick()}>Sign in</Button>
              </Box>
              <Box mt={2} mb={2}>
                <Divider />
              </Box>
              <Box mt={2} mb={1} textAlign="center">
                <Typography
                  component={Link}
                  to="/register"
                  variant="subtitle1"
                  sx={{ textDecoration: 'none' }}>Don&apos;t have an account?
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Grid>
    </div>
    // <LoginContainer>
    //   <Logo src={logo} />
    //   <WelcomeMessage>Welcome Abex users</WelcomeMessage>

  //   <TextField
  //     type="email"
  //     label="Email"
  //     required={true}
  //     placeholder="Enter email"
  //     value={email}
  //     autoFocus={true}
  //     onChange={onEmailChange}
  //     onKeyPress={onKeypress}
  //     className="n-bottom-spacing"
  //     error={emailErrorMessage}
  //   />
  //   <TextField
  //     type={passwordTextFieldType}
  //     label="Password"
  //     required={true}
  //     placeholder="Enter password"
  //     onChange={onPasswordChange}
  //     onKeyPress={onKeypress}
  //     value={password}
  //     className="n-bottom-spacing"
  //     rightTemplate={<PasswordTemplate onClick={changePasswordTextFieldType}>{passwordRightTemplate}</PasswordTemplate>}
  //     error={passwordErrorMessage}
  //   />

  //   <Button text="Sign in" onClick={onLoginClick} width="100%" height="35px" dataTestId="signin-button" />
  // </LoginContainer>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      login: metadataActions.login,
      googleLogin: metadataActions.googleLogin,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(LoginPage);
