import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { metadataReducer } from './metadata/metadata-reducer';
// import { adminUserReducer } from './admin-user/admin-user-reducer';
// import { accountReducer } from './account/account-reducer';

const reducer = {
  metadata: metadataReducer,
  // adminUser: adminUserReducer,
  // account: accountReducer,
};

export const mizaStore = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof mizaStore.getState>;
export type AppDispatch = typeof mizaStore.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
