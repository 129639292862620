import { createReducer } from '@reduxjs/toolkit';
import MizaEnums from '../../support/miza-enums';
import { metadataActions } from './metadata-actions';

type MetaDataState = {
  currentUser: {
    isLogged: boolean;
    teamId: null | number
  },
  displayMode: MizaEnums.DisplayMode
};

const initialMetadataState: MetaDataState = {
  currentUser: {
    isLogged: false,
    teamId: null,
  },
  displayMode: MizaEnums.DisplayMode.Light,
};

export const metadataReducer = createReducer(initialMetadataState, (builder) => {
  builder
    .addCase(metadataActions.logout.success, (state: MetaDataState) => {
      // console.log(initialMetadataState.currentUser);
      state.currentUser = initialMetadataState.currentUser;
    })
    .addCase(metadataActions.getLoggedInUserData.success, (state: MetaDataState, action: any) => {
      const { isLogged, teamId } = action.payload;

      state.currentUser = {
        isLogged: isLogged,
        teamId: teamId ?? initialMetadataState.currentUser.teamId,
      };
    })
    .addCase(metadataActions.login.success, (state: MetaDataState, action: any) => {
      const { token, teamId } = action.payload;

      state.currentUser = {
        isLogged: token !== null && token !== '' && token !== undefined,
        teamId: teamId ?? initialMetadataState.currentUser.teamId,
      };
    })
    .addCase(metadataActions.googleLogin.success, (state: MetaDataState, action: any) => {
      const { token, teamId } = action.payload;

      state.currentUser = {
        isLogged: token !== null && token !== '' && token !== undefined,
        teamId: teamId ?? initialMetadataState.currentUser.teamId,
      };
    })
    .addCase(metadataActions.getLoggedInUserData.error, (state: MetaDataState) => {
      state.currentUser = initialMetadataState.currentUser;
    })
    .addCase(metadataActions.setDisplayMode.success, (state: MetaDataState, action: any) => {
      const { displayMode } = action.payload;

      state.displayMode = displayMode;
    })
    .addCase(metadataActions.setDisplayMode.error, (state: MetaDataState) => {
      state.displayMode = initialMetadataState.displayMode;
    });
});
