import APIRequestHelper from '../helpers/api-request-helper';
import dateTimeHelper from '../helpers/date-time-helper';
import MizaConstants from '../support/miza-constants';
import MizaEnums from '../support/miza-enums';

const UserService = {
  async login(username: string, password: string) {
    const payload = {
      'username': username,
      'password': password,
    };
    const response = await APIRequestHelper.sendRequest('POST', 'authenticate/login', true, payload);

    if (response.isSuccess && response.data?.['token']) {
      localStorage.setItem('token', response.data['token']);
      localStorage.setItem('email', username);
      localStorage.setItem('teamId', response.data['team_id']);

      return response.data;
    }

    UserService.logout();

    return null;
  },

  async googleLogin(tokenId: string) {
    const payload = {
      'tokenId': tokenId,
      'databaseVersion': MizaConstants.DATABASE_VERSION,
      'platform': MizaConstants.PLATFORM,
      'timeZoneOffset': dateTimeHelper.timeZoneOffset(),

    };
    const response = await APIRequestHelper.sendRequest('POST', 'authenticate/login-google', true, payload);

    if (response.isSuccess && response.data?.['token']) {
      localStorage.setItem('token', response.data['token']);
      localStorage.setItem('email', response.data?.['email']);
      localStorage.setItem('teamId', response.data['team_id']);

      return response.data;
    }

    UserService.logout();

    return null;
  },

  logout(): boolean {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('teamId');

    return true;
  },

  isLogged(): boolean {
    const token = localStorage.getItem('token');
    return token !== null && token !== '' && token !== undefined;
  },

  getLoggedInTeamId(): null | number {
    const teamId = localStorage.getItem('teamId');
    return teamId ? parseInt(teamId) : null;
  },

  getDisplayMode(): null | MizaEnums.DisplayMode {
    const displayMode = localStorage.getItem('display-mode');
    return (displayMode !== null && displayMode !== '' &&
    displayMode !== undefined) ? parseInt(displayMode) as MizaEnums.DisplayMode : null;
  },

  setDisplayMode(displayMode: MizaEnums.DisplayMode) {
    localStorage.setItem('display-mode', displayMode.toString());
  },

  async getUser(id: number) {
    return APIRequestHelper.sendRequest('GET', `admin/users/${id}`, false);
  },
};

export default UserService;
