import APIRequestHelper from '../helpers/api-request-helper';

const AccountService = {
  async getAccounts() {
    const payload = {
      'sorts': [
        {'name': 'IsActive', 'is_descending': true},
        {'name': 'name', 'is_descending': false},
      ],
    };
    return await APIRequestHelper.sendRequest('POST', 'account/filtered', false, payload);
  },
};
export default AccountService;
