// import { Console } from 'console';
import { ThunkPropsInterface } from '../../helpers/store-helper';
import { UserService } from '../../services';
import MizaEnums from '../../support/miza-enums';
import { metadataActions } from './metadata-actions';

export const metadataThunks = {
  login: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    email: string,
    password: string,
    successCallback?: Function,
    errorCallback?: Function
  ) => {
    const data = await UserService.login(email, password);

    if (data) {
      dispatch(actionSuccess(data));
      dispatch(metadataActions.getLoggedInUserData);
      successCallback?.();
    } else {
      dispatch(actionError());
      errorCallback?.();
    }
  },

  googleLogin: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    tokenId: string,
    successCallback?: Function,
    errorCallback?: Function
  ) => {
    const data = await UserService.googleLogin(tokenId);

    if (data) {
      dispatch(actionSuccess(data));
      dispatch(metadataActions.getLoggedInUserData);
      successCallback?.();
    } else {
      dispatch(actionError());
      errorCallback?.();
    }
  },

  logout: async ({ dispatch, actionSuccess, actionError }: ThunkPropsInterface, successCallback?: Function) => {
    try {
      const isSuccess = UserService.logout();

      if (isSuccess) {
        dispatch(actionSuccess({ isLogged: false, teamId: null }));
        successCallback?.();
      } else {
        dispatch(actionError());
      }
    } catch (error) {
      dispatch(actionError());
    }
  },

  getLoggedInUserData: async ({ dispatch, actionSuccess, actionError }: ThunkPropsInterface, callback?: Function) => {
    try {
      const isLogged = UserService.isLogged();
      const loggedInTeamId = UserService.getLoggedInTeamId();

      if (!isLogged) {
        dispatch(actionSuccess({}));
        callback?.();
        return;
      }

      if (!loggedInTeamId) {
        dispatch(actionError());
        callback?.();
        return;
      }

      // const response = await UserService.getUser(loggedInUserId);
      // const user = response.data?.user_profiles?.[0];

      // if (!response.isSuccess || !user) {
      //   dispatch(actionError());
      //   callback?.();
      //   return;
      // }

      dispatch(actionSuccess({ isLogged: true, teamId: loggedInTeamId }));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },

  getDisplayMode: async ({ dispatch, actionError }: ThunkPropsInterface, callback?: Function) => {
    try {
      const displayMode = UserService.getDisplayMode();

      if (displayMode == null) {
        dispatch(metadataActions.setDisplayMode(MizaEnums.DisplayMode.Light));
        callback?.();
        return;
      }

      // const response = await UserService.getUser(loggedInUserId);
      // const user = response.data?.user_profiles?.[0];

      // if (!response.isSuccess || !user) {
      //   dispatch(actionError());
      //   callback?.();
      //   return;
      // }

      dispatch(metadataActions.setDisplayMode(displayMode));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },

  setDisplayMode: async (
    { dispatch, actionSuccess, actionError }: ThunkPropsInterface,
    displayMode: MizaEnums.DisplayMode,
    callback?: Function) => {
    try {
      UserService.setDisplayMode(displayMode);

      dispatch(actionSuccess({ displayMode: displayMode }));
      callback?.();
    } catch (error) {
      dispatch(actionError());
    }
  },
};
