import moment from 'moment';
import MizaConstants from '../support/miza-constants';

const dateTimeHelper = {
  today(): string {
    // new Date(new Date().setHours(0,0,0,0))
    return moment.utc().format(MizaConstants.DateFormat.ISO_DATE_FORMAT);
  },
  timeZoneOffset(): number {
    return moment().utcOffset();
  },
  format(date: Date, format: string): string {
    return moment(date).isValid() ? moment(date).format(format) : '';
  },
  formatToUTC: (date: Date, format: string) => {
    return date && moment(date).isValid() ? moment(date).utc().format(format) : undefined;
  },
  getDateObject: (date: string) => {
    return date && moment(date).isValid() ? moment(date).toDate() : undefined;
  },
  getDaysDiff: (end: Date, start: Date) => {
    const endDate = moment(end).utc().format(MizaConstants.DateFormat.ISO_DATE_FORMAT);
    const startDate = moment(start).utc().format(MizaConstants.DateFormat.ISO_DATE_FORMAT);
    return start && end ? moment(endDate).diff(moment(startDate), 'days') : 0;
  },
  getLocalizedDayString: (date: string) =>
    moment(date).calendar({
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'MMMM Do',
      sameElse: 'MMMM Do',
    }),
  getHoursAndMinutes: (date: string) => moment(date).format('HH:mm'),
};

export default dateTimeHelper;
