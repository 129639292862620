import React, { useEffect, useState } from 'react';
import { AccountService } from '../../services';
import { Grid } from '@mui/material';
import AccountCard from './AccountCard';
import MizaEnums from '../../support/miza-enums';

const AccountsPage = () => {
  const [accounts, setAccounts] = useState<any>([]);
  // const [isLoaded, setIsLoaded] = useState<boolean>(true);
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function init() {
      const response = await AccountService.getAccounts();

      console.log('AccountsPage.useEffect()', response);

      if (!response.isSuccess) {
        setAccounts([]);
      } else {
        setAccounts(response.data);
      }
      // setIsLoading(false);
    }

    init();
  }, []);


  // const loadingState = (<div></div>);
  // {/* <div>
  //   {isLoading ? <loadingState /> : accounts.map((account) => <AccountCard id={0} name={account.name}
  // description={account.description} Type={MizaEnums.Checking} creationDate={account.creationDate} />)}
  // </div> */}
  // {/* {isLoading ? <AdminUserCardLoadingState /> : accounts.map((account) => <accountCard key={user.id} id={user.id} />)} */}

  return (
    <Grid container spacing={{ xs: 2, md: 4 }} columns={{ xs: 1, sm: 8, md: 12 }}>
      {accounts.map((account: any) => <AccountCard
        key={account.id}
        id={account.id}
        name={account.name}
        note={account.note}
        type={MizaEnums.AccountType.Checking}
        creationDate={account.creationDate}
        balance={account.balance}
        initialAmount={account.initial_amount}
        creditLimit={account.credit_limit}
        isLinked={account.is_linked}
        isDefault={account.is_default}
        isActive={account.is_active}
        isCredit={account.type == MizaEnums.AccountType.CreditCard ||
                  account.type == MizaEnums.AccountType.LineOfCredit ||
                  account.type == MizaEnums.AccountType.Loan}
        includeInBalance={account.include_in_balance}
        order={account.order}
        logo={account.logo}
        url={account.url} />)}
    </Grid>
  );
};

export default AccountsPage;
