import { CardContent, Typography, Card, CardHeader, CardMedia, IconButton, Menu, MenuItem, Grid } from '@mui/material';
import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MizaTypes } from '../../support/miza-types';
import {EditOutlined, StarOutlined, DeleteOutlined, ListOutlined } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';


export const AccountCard = (props: MizaTypes.Account) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // MizaConstants.ACCOUNT_ICONS[props.type]
  // <Card sx={{ minWidth: 250, maxWidth: 350 }} >
  return (
    <Grid item xs={2} sm={4} md={4} sx={{minWidth: 250 }}>
      <Card variant="outlined">
        <CardHeader
          sx={{alignItems: 'start'}}
          avatar={ props.logo != null ? <CardMedia
            component="img"
            height="36"
            image={`data:image/jpeg;base64,${props.logo}`}
            alt="Bank name"/> :
            <div></div>
          }
          action={
            <IconButton aria-label="actions">
              <MoreVertIcon
                onClick={handleClick} />
              <Menu
                id="menu-popular-card"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <EditOutlined sx={{ marginRight: '10px' }} />
                Edit
                </MenuItem>
                <MenuItem onClick={handleClose} sx={props.isDefault ? {display: 'none'} : {}} >
                  <StarOutlined sx={{ marginRight: '10px' }} />
                Set as default
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListOutlined sx={{ marginRight: '10px' }} />
                View transactions
                </MenuItem>
                <MenuItem onClick={handleClose} >
                  <DeleteOutlined sx={{ marginRight: '10px', color: 'red' }} />
                  <div style={{color: 'red'}}>Delete</div>
                </MenuItem>
              </Menu>
            </IconButton>
          }
          title={props.name}
          subheader={props.note}
        />
        {/* theme.palette.error.main */}
        <CardContent>
          <Typography variant="h3" color={props.isCredit ? theme.palette.error.main : theme.palette.success.main}>
            {props.balance}
          </Typography>
        </CardContent>
        {/* <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions> */}
      </Card>
    </Grid>
  );
};

export default AccountCard;
