import './App.css';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ThemeProvider } from '@mui/material/styles';

import MizaRoutes from './support/MizaRoutes';
import { metadataActions } from './store/metadata/metadata-actions';
// import { ThemeProvider } from '@mui/material/styles';

// defaultTheme
import themes from './themes';

import MizaEnums from './support/miza-enums';
import { CssBaseline } from '@mui/material';

type AppProps = {
  isLogged: boolean;
  displayMode: MizaEnums.DisplayMode,
  getLoggedInUserData: (callback?: Function) => void;
  getDisplayMode: (callback?: Function) => void;
};

const App = (props: AppProps) => {
  const { isLogged, displayMode, getLoggedInUserData, getDisplayMode } = props;
  const [isReady, setIsReady] = useState<boolean>(false);
  const [customization, setcustomization] = useState<any>({mode: 'light'});

  useEffect(() => {
    getLoggedInUserData(() => {
      setIsReady(true);
    });
    getDisplayMode(() => {
      setcustomization({
        mode: displayMode == MizaEnums.DisplayMode.Dark ? 'dark' : 'light',
      });
    }
    );
  }, []);

  useEffect(() => {
    setcustomization({
      mode: displayMode == MizaEnums.DisplayMode.Dark ? 'dark' : 'light',
    });
  }, [displayMode]);

  // Update the theme only if the mode changes
  // themes = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  // Update the theme only if the mode changes
  // const theme = React.useMemo(() => createTheme(getDesignTokens('dark')), ['dark']);

  return (
    <ThemeProvider theme={themes(customization)}>
      <CssBaseline />
      {isReady && <MizaRoutes isLogged={isLogged} />}
    </ThemeProvider>
  );
  // {<ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>}
  // {</ThemeProvider>}
};

const mapStateToProps = (state: any) => ({
  isLogged: state.metadata.currentUser.isLogged,
  displayMode: state.metadata.displayMode,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getLoggedInUserData: metadataActions.getLoggedInUserData,
      getDisplayMode: metadataActions.getDisplayMode,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
