import React, { useCallback } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AccountsPage from '../pages/account/Accounts';
import LoginPage from '../pages/authentication/Login';
import RegisterPage from '../pages/authentication/Register';
import HomePage from '../pages/home/home';
import MainLayout from '../pages/shared/MainLayout';
import TransactionsPage from '../pages/transaction/Transactions';
import BudgetPage from '../pages/budget/Budget';
import GoalsPage from '../pages/goal/Goals';

type MizaRoutesProps = {
  isLogged: boolean;
};

const LoggedInLayout = () => {
  return (
    <>
      <MainLayout />
    </>
  );
};

const MizaRoutes = (props: MizaRoutesProps) => {
  const { isLogged } = props;
  const location = useLocation();

  const protectRouteElement = useCallback(
    (element: JSX.Element) => {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      if (isLogged) {
        return element;
      }
      return <Navigate to="/login" state={{ from: location }} />;
    },
    [isLogged, location]
  );

  return (
    <Routes>
      <Route path="login" element={isLogged ? <Navigate to={location.pathname != '/login' ? location.pathname : '/home'} replace /> : <LoginPage/>} />
      <Route path="register" element={isLogged ? <Navigate to={location.pathname != '/register' ? location.pathname : '/home'} replace /> : <RegisterPage/>} />
      <Route element={protectRouteElement(<LoggedInLayout />)}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/accounts" element={<AccountsPage />} />
        <Route path="/transactions" element={<TransactionsPage />} />
        <Route path="/budget" element={<BudgetPage />} />
        <Route path="/goals" element={<GoalsPage />} />
      </Route>
      <Route path="*" element={<Navigate to={isLogged ? '/home' : '/login'} />} />
    </Routes>
  );
};

export default MizaRoutes;
