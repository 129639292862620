import React from 'react';
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  ListItemButton,
} from '@mui/material';

type MenuItemProps = {
    title: string;
    icon: any;
    isOpen: boolean;
    callbackAction: any;
};

const MenuItem = (props: MenuItemProps) => {
  const { title, icon, isOpen, callbackAction } = props;
  return (
    <ListItem key={title} disablePadding sx={{ display: 'block' }} onClick={callbackAction}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: isOpen ? 'initial' : 'center',
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: isOpen ? 3 : 'auto',
            justifyContent: 'center',
          }}
        >
          {icon}
          {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
        </ListItemIcon>
        <ListItemText primary={title} sx={{ opacity: isOpen ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
};

export default MenuItem;
