import {AccountBalanceOutlined, SavingsOutlined, PaymentsOutlined, CreditCardOutlined, CardMembershipOutlined, LensBlurOutlined } from '@mui/icons-material';

const MizaConstants = {
  DATABASE_VERSION: 2,
  PLATFORM: 3, // Web

  DEFAULT_PAGE_SIZE: 15,

  BORDER_RADIUS_SMALL: '5px',
  BORDER_RADIUS_MEDIUM: '8px',
  BORDER_RADIUS_LARGE: '20px',

  GOOGLE_CLIENT_ID: '641981710258-d36bepce8pcpc7obhce1dejqd6mm8nrh.apps.googleusercontent.com',

  ACCOUNT_ICONS: [
    AccountBalanceOutlined,
    SavingsOutlined,
    PaymentsOutlined,
    CreditCardOutlined,
    CreditCardOutlined,
    CardMembershipOutlined,
    LensBlurOutlined,
  ],
  DateFormat: {
    SYNCFUSION_DEFAULT: 'dd/MM/yyyy',
    MOMENT_DEFAULT: 'DD/MM/YYYY',
    SYNCFUSION_ISO: 'yyyy-MM-dd',
    ISO_DATE_FORMAT: 'YYYY-MM-DD',
  },
  DateTimeFormat: {
    SYNCFUSION_ISO: 'yyyy-MM-dd (HH:mm)',
  },
};

export default MizaConstants;
