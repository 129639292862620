const BASE_URI = 'https://api.timeforbudget.com/api'; // Live
// const BASE_URI = "https://127.0.0.1:44345/api"; //Debug
const httpStatus = {
  OK: 200,
  Created: 201,
  Accepted: 202,
  NoContent: 204,
};

export interface APIResponse {
  data: any;
  isSuccess: boolean;
  statusCode: number;
}

const APIRequestHelper = {
  async sendGetRequest(action: string) {
    const token = localStorage.getItem('token');

    if (token !== '') {
      const url = `${BASE_URI}/${action}`;
      const fetchParameters = {
        method: 'GET',
        headers: {
          'content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`,
        },
      };

      const result = await fetch(url, fetchParameters);
      const response = await result.json();
      return {
        data: response,
        StatusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return {
      data: '',
      isSuccess: false,
    };
  },

  async sendRequest(httpMethod: string, action: string, anonymous: boolean, payload?: string | {} | null) {
    // let headers = {
    //   'content-type': 'application/json',
    //   'Accept': 'application/json',
    //   'ApiKey': 'c41eea85-3070-46e1-8d6a-0580b1f670a7',
    // };

    const requestHeaders = new Headers();
    requestHeaders.set('Content-Type', 'application/json');
    requestHeaders.set('Accept', 'application/json');
    requestHeaders.set('ApiKey', 'c41eea85-3070-46e1-8d6a-0580b1f670a7'); // TODO: Generate a new API Key for WEB

    if (!anonymous) {
      //  {
      //   'content-type': 'application/json',
      //   'Accept': 'application/json',
      //   'ApiKey': 'c41eea85-3070-46e1-8d6a-0580b1f670a7',
      // };
      requestHeaders.set('Content-Type', 'application/json');
      const token = localStorage.getItem('token');
      const teamId = localStorage.getItem('teamId');
      if (token == '' || token == null || teamId == '' || teamId == null) {
        return {
          data: '',
          isSuccess: false,
        };
      }
      requestHeaders.set('Authorization', `Bearer ${token}`);
      requestHeaders.set('team_id', teamId);
    }

    const url = `${BASE_URI}/${action}`;
    const fetchParameters = {
      method: httpMethod,
      headers: requestHeaders,

      body: JSON.stringify(payload),
    };
      // if (payload) {
      //   fetchParameters.body = JSON.stringify(payload);
      // }
    const result = await fetch(url, fetchParameters);
    const response = await result.json();

    return {
      data: response,
      StatusCode: result.status,
      isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
    };
  },

  async sendDeleteRequest(action: string) {
    const token = localStorage.getItem('token');

    if (token !== '') {
      const url = `${BASE_URI}/${action}`;
      const fetchParameters = {
        method: 'DELETE',
        headers: {
          'content-Type': 'application/json; charset=utf-8',
          'Authorization': `Bearer ${token}`,
        },
      };

      const result = await fetch(url, fetchParameters);
      const response = await result.json();
      return {
        data: response,
        StatusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return {
      data: '',
      isSuccess: false,
    };
  },

  async login(username: string, password: string) {
    const result = await fetch(`${BASE_URI}/authenticate/login`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Accept': 'application/json',
        'ApiKey': 'c41eea85-3070-46e1-8d6a-0580b1f670a7' }, // TODO: use a different API-KEY?
      body: JSON.stringify({
        'username': username,
        'password': password,
      }),
    });

    const response = await result.json();
    let apiResponse: APIResponse = { data: null, statusCode: httpStatus.NoContent, isSuccess: false };

    if (response && response.token) {
      apiResponse = {
        data: response,
        statusCode: result.status,
        isSuccess:
          result.status === httpStatus.OK ||
          result.status === httpStatus.Created ||
          result.status === httpStatus.Accepted ||
          result.status === httpStatus.NoContent,
      };
    }
    return apiResponse;
  },
};

export default APIRequestHelper;
