import * as React from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// material-ui
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import {AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  AppBarProps as MuiAppBarProps,
  useMediaQuery,
  Toolbar,
  Box,
  IconButton,
  Button,
  Stack,
  // CssBaseline,
  List } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Dashboard,
  AccountBalance,
  CreditCard,
  AccountBalanceWallet,
  PieChart,
  DarkMode,
  Logout } from '@mui/icons-material';
import MenuItem from './MenuItem';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
// import { BrowserView, MobileView } from 'react-device-detect';

import { metadataActions } from '../../store/metadata/metadata-actions';
import MizaEnums from '../../support/miza-enums';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '15px',
  color: theme.palette.common.white,
  background: theme.palette.primary.main,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  isOpen?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, isOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isOpen && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

type MainLayoutProps = {
  logout: (successCallback: Function) => void;
  setDisplayMode: (displayMode: MizaEnums.DisplayMode, successCallback?: Function) => void;
};

const MainLayout = (props: MainLayoutProps) => {
  const { logout, setDisplayMode } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(true);
  const [isDarkMode, setIsDarkMode] = React.useState(MizaEnums.DisplayMode.Light);
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleOpenDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout(() => {
      navigate('/login');
    });
  };

  const handleDisplayMode = () => {
    const displayMode = isDarkMode ? MizaEnums.DisplayMode.Light : MizaEnums.DisplayMode.Dark;
    setDisplayMode(displayMode, () => {
      setIsDarkMode(displayMode);
    });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: isOpen ? theme.transitions.create('width') : 'none',
        }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: drawerWidth, display: 'flex', justifyContent: 'space-between' }}>
            <img src='logo-miza.png' alt="Miza" width="120px" />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleOpenDrawer}
              edge="start"
              sx={{
                marginRight: 3,
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Stack direction='row' spacing={2}>
            <Button color='inherit'>Feature1</Button>
          </Stack>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={isOpen}
        anchor="left"
        sx={{
          '& .MuiDrawer-paper': {
            // width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px',
            },
          },
        }}
        // ModalProps={{ keepMounted: true }}
        // color="inherit"
      >
        {/* <DrawerHeader color='inherit'>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader> */}
        {/* <BrowserView> */}
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <List>
            <MenuItem title='Overview' icon={<Dashboard/>} isOpen={isOpen} callbackAction={() => navigate('/home')} />
            <MenuItem title='Accounts' icon={<AccountBalance/>} isOpen={isOpen} callbackAction={() => navigate('/accounts')} />
            <MenuItem title='Transactions' icon={<CreditCard/>} isOpen={isOpen} callbackAction={() => navigate('/transactions')} />
            <MenuItem title='Budget' icon={<AccountBalanceWallet/>} isOpen={isOpen} callbackAction={() => navigate('/budget')} />
            <MenuItem title='Goals' icon={<PieChart/>} isOpen={isOpen} callbackAction={() => navigate('/goals')} />
          </List>
          <List>
            <MenuItem title='Display mode' icon={<DarkMode/>} isOpen={isOpen} callbackAction={() => handleDisplayMode()} />
            <MenuItem title='Logout' icon={<Logout/>} isOpen={isOpen} callbackAction={() => handleLogout()} />
          </List>
          {/* <Divider />
          <List>
            {['All mail', 'Trash', 'Spam'].map((text, index) => (
              <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: isOpen ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} sx={{ opacity: isOpen ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List> */}
        </PerfectScrollbar>
        {/* </BrowserView> */}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader />
        <Box sx={{ flexGrow: 1, p: 5 }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      logout: metadataActions.logout,
      setDisplayMode: metadataActions.setDisplayMode,
    },
    dispatch
  );


export default connect(null, mapDispatchToProps)(MainLayout);


/*
            <Box mt={4} mb={1}>
              {<IconButton size='large' edge='start' color="primary"
              color='inherit' aria-label='logo' onClick={() => handleLogout()} />}
              <IconButton size='large' edge='start' aria-label='logout' onClick={() => handleLogout()}>
                <Logout />
              </IconButton>
            </Box>
            <Box mt={4} mb={1}>
              {<IconButton size='large' edge='start' color='inherit' aria-label='logo' onClick={() => handleLogout()} />}
              <IconButton size='large' edge='start' aria-label='display mode' onClick={() => handleDisplayMode()}>
                <DarkMode />
              </IconButton>
            </Box>
*/
