import { createAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';

export interface ThunkPropsInterface {
  dispatch: AppDispatch;
  getState: () => RootState;
  actionPending: any;
  actionSuccess: any;
  actionError: any;
}

const storeHelper = {
  createThunk(actionType: string, payloadCreator: any) {
    const actionPending = createAction(`${actionType}/pending`);
    const actionSuccess = createAction(`${actionType}/success`);
    const actionError = createAction(`${actionType}/error`);

    const actionCreator = (...args: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
      await payloadCreator.apply(null, [
        {
          dispatch,
          getState,
          actionPending,
          actionSuccess,
          actionError,
        },
        ...args,
      ]);
    };

    return Object.assign(actionCreator, {
      pending: actionPending,
      error: actionError,
      success: actionSuccess,
    });
  },
  getStateAsArray: function (state: any) {
    return Object.keys(state)
      .filter((key) => !Number.isNaN(Number.parseInt(key)))
      .map((key) => state[Number.parseInt(key)]);
  },
};

export default storeHelper;
